import React from 'react';

export default class GoBack extends React.Component {

    back(){
        window.history.back()
    }

    render() {
        return (
            <button className="go-back" onClick={() => this.back()}>Back</button>
        );
    }
}
